import React from "react";
import AvailablePlanCard from "./AvailablePlanCard/AvailablePlanCard";

const AvailablePlans = ({
  subscriptionValue,
  availablePlansData,
  setSubscriptionValue,
  activePlan,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 relative z-10">
      {availablePlansData?.map((plan, index) => (
        <AvailablePlanCard
          key={index}
          id={index}
          isActivePlan={
            activePlan?.toLowerCase() === plan.planName?.toLowerCase()
          }
          planImage={plan.planImage}
          popularPlan={plan.popularPlan}
          name={plan.planName}
          description={plan.planDescription}
          planPriceMonthly={plan.planPriceMontly}
          planPriceYearly={plan.planPriceYearly}
          features={plan.featuresAvailable}
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          planId={plan?._id}
        />
      ))}
    </div>
  );
};

export default AvailablePlans;
