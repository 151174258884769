import React from "react";

const ShowAudio = ({ audioBlob }) => {
  return (
    <div className="flex items-center space-x-2 justify-between bg-white rounded-md shadow-md w-full mt-4 px-4 py-3 border">
      <div className="flex items-center space-x-2">
        <audio controls muted autoPlay={false}>
          <source src={audioBlob} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
};

export default ShowAudio;
