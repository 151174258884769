import React, { useContext } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";

const PaypalSubscriptionButtonsRender = ({
  error,
  setError,
  onApprove,
  planId,
  subscriptionTimeline,
  successUrl,
  errorUrl,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  // paypal button loading state hook provided by @paypal/react-paypal-js
  const [{ isPending, isRejected }] = usePayPalScriptReducer();
  const createSubscriptionOrder = async () => {
    try {
      return axios
        .post(
          `${getURLs("paypal-checkout")}/subscription/create-order`,
          {
            subscriptionId: planId,
            subscriptionTimeline,
            successUrl,
            errorUrl,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          return res?.data?.order?.id;
        })
        .catch((err) => {
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          ) {
            setError(err?.response?.data?.message);
          }
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setError(error?.message);
    }
  };

  return (
    <div className="flex flex-col items-center w-full mt-4">
      {error?.length > 0 && (
        <span className="font-inter font-medium text-xs sm:text-sm text-red-400 block mb-2">
          {error}
        </span>
      )}
      {isPending ? (
        <span className="text-center w-full text-black font-medium font-inter">
          Please wait, Paypal loading...
        </span>
      ) : isRejected ? (
        <span className="font-inter font-medium text-xs sm:text-sm text-red-400 block mb-2">
          Failed to load Paypal checkout
        </span>
      ) : (
        <PayPalButtons
          className="max-w-xl w-full"
          fundingSource="paypal"
          createSubscription={(data, actions) => createSubscriptionOrder()}
          onApprove={onApprove}
        />
      )}
    </div>
  );
};

export default PaypalSubscriptionButtonsRender;
