import { UserCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";
import ImageUploadingModal from "../../ImageUploadingModal";
import { DarkModeContext } from "../../../context/darkModeContext";

const ProfileImageUpdate = ({
  userInfo,
  authToken,
  isAdminEditingProfile,
  handleProfileChangeSuccess,
}) => {
  const { updateUser } = useContext(UserContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const [tempfile, setTempfile] = useState();

  const [editActive, setEditActive] = useState(false);
  const [editValues, setEditValues] = useState({
    profileImage: userInfo?.profileImage,
  });
  const [prevValues, setPrevValues] = useState({
    profileImage: userInfo?.profileImage,
  });
  const [uploadingState, setUploadingState] = useState(false);

  const handleChange = (e) => {
    setEditActive(true);
    if (e.target.files && e.target.files[0]) {
      setTempfile(e.target.files[0]);
      const img = URL.createObjectURL(e.target.files[0]);
      setEditValues({ profileImage: img });
    }
  };

  const handleUpload = () => {
    if (JSON.stringify(prevValues) !== JSON.stringify(editValues)) {
      setUploadingState(true);
      axios
        .post(
          getURLs("image-upload-url"),
          { imageFor: "profileImage" },
          {
            headers: { "auth-token": authToken },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetch(res?.data?.url, {
            method: "PUT",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: tempfile,
          })
            .then(() => {
              const image = res?.data?.url?.split("?")[0];
              axios
                .put(
                  getURLs("update-information"),
                  { profileImage: image },
                  {
                    withCredentials: true,
                    headers: {
                      "auth-token": authToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.status === 200) {
                    const { user } = res.data;
                    if (isAdminEditingProfile) {
                      handleProfileChangeSuccess(user);
                    } else {
                      updateUser({ ...user, authToken: authToken });
                    }
                    setEditActive(false);
                    setEditValues({
                      profileImage: user?.profileImage,
                    });
                    setPrevValues({
                      profileImage: user?.profileImage,
                    });
                    setUploadingState(false);
                  }
                  setEditActive(false);
                })
                .catch((err) => {
                  setUploadingState(false);
                  setEditActive(false);
                  console.log(err);
                });
            })
            .catch((err) => {
              setUploadingState(false);
              setEditActive(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEditActive(false);
    }
  };

  useEffect(() => {
    setEditValues({
      profileImage: userInfo?.profileImage,
    });
    setPrevValues({
      profileImage: userInfo?.profileImage,
    });
  }, [userInfo]);

  return (
    <>
      <div className="flex flex-col px-5 py-4 w-full">
        <h1 className="font-urbanist font-semibold text-xl dark:text-white text-black block mb-5">
          Image Upload
        </h1>

        <h5 className="font-urbanist font-medium dark:text-white text-gray30 text-sm block mb-8">
          Upload JPEG or PNG format
        </h5>

        <div className="flex flex-col dark:bg-purple2 px-4 py-4 rounded-3xl border dark:border-purple15">
          <div className="bg-gray43 dark:bg-purple17 rounded-2xl w-full flex items-center justify-center mb-5">
            {!editActive ? (
              userInfo?.profileImage ? (
                <img
                  loading="lazy"
                  src={userInfo?.profileImage}
                  alt="profile"
                  className="h-32 w-32 object-cover rounded-full"
                />
              ) : (
                <UserCircleIcon className="dark:text-white text-gray20 h-32 w-32 rounded-full" />
              )
            ) : (
              <div>
                <input
                  type="file"
                  id="input-file-upload"
                  accept={".png, .jpg, .jpeg"}
                  hidden
                  onChange={handleChange}
                />
                <label id="label-file-upload" htmlFor="input-file-upload">
                  {editValues?.profileImage ? (
                    <img
                      src={editValues?.profileImage}
                      alt="icon"
                      className="h-32 w-32 object-cover rounded-full"
                    />
                  ) : (
                    <UserCircleIcon className="dark:text-white text-gray20 h-32 w-32 rounded-full" />
                  )}
                </label>
              </div>
            )}
          </div>

          <div className="border dark:border-purple15 rounded-lg w-full flex flex-col md:flex-row md:justify-between items-start md:items-center px-4 py-2">
            <p className="font-urbanist font-normal dark:text-white text-gray-400 text-sm">
              Profile Photo
            </p>
            <input
              type="file"
              id="input-file-upload"
              accept={".png, .jpg, .jpeg"}
              hidden
              onChange={handleChange}
            />
            {!editActive ? (
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  className="px-4 py-2 cursor-pointer rounded-lg"
                  style={{
                    background: !darkMode
                      ? "linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box"
                      : "linear-gradient(#2E0053, #2E0053) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box",
                    border: "1px solid transparent",
                  }}
                >
                  <span
                    className="text-sm font-urbanist font-medium"
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    Edit Image
                  </span>
                </div>
              </label>
            ) : (
              <div
                className="px-4 py-2 cursor-pointer rounded-lg"
                style={{
                  background: !darkMode
                    ? "linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box"
                    : "linear-gradient(#2E0053, #2E0053) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box",
                  border: "1px solid transparent",
                }}
                onClick={handleUpload}
              >
                <span
                  className="text-sm font-urbanist font-medium"
                  style={{
                    backgroundImage:
                      "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Upload Image
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ImageUploadingModal
        modalOpen={uploadingState}
        message={` Please wait your information is getting updated...`}
      />
    </>
  );
};

export default ProfileImageUpdate;
