import createDataContext from "../DataContext";
import reducer, { initialState } from "./reducer";
import {
  updateUser,
  updateSearchesLeftForUser,
  updateUserBotInfo,
  updateUserBotIdInfo,
  updateUserChatIdInfo,
  logoutUser,
  updateUserBookmarkedCourses,
} from "./actions";

export const { Context: UserContext, Provider: UserProvider } =
  createDataContext(
    reducer,
    {
      updateUser,
      updateSearchesLeftForUser,
      updateUserBotIdInfo,
      updateUserBotInfo,
      updateUserChatIdInfo,
      logoutUser,
      updateUserBookmarkedCourses,
    },
    initialState
  );
