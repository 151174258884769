import {
  AcademicCapIcon,
  BookOpenIcon,
  HandRaisedIcon,
  QuestionMarkCircleIcon,
  // TrophyIcon,
  HomeIcon,
  PowerIcon,
  BuildingLibraryIcon,
  CurrencyDollarIcon,
  QueueListIcon,
  UserCircleIcon,
  FolderPlusIcon,
  DocumentArrowUpIcon,
  // ArrowPathIcon,
  // BellIcon,
  // ChartPieIcon,
  // Cog6ToothIcon,
  // LockClosedIcon,
  // ShoppingBagIcon,
  // StarIcon,
  // TrashIcon,
  // UserIcon,
  // UsersIcon,
} from "@heroicons/react/24/outline";
import {
  RectangleGroupIcon,
  CalculatorIcon,
  BeakerIcon,
} from "@heroicons/react/24/solid";

import StudentIconBlack from "../images/student_icon_black.svg";
import StudentIconWhite from "../images/student_icon_white.svg";
import OrdersIconBlack from "../images/orders_icon_black.svg";
import OrdersIconWhite from "../images/orders_icon_white.svg";
import EarningsIconBlack from "../images/earnings_icon_black.svg";
import EarningsIconWhite from "../images/earnings_icon_white.svg";

// instructor sidebar iitems
export const DashboardMenu = [
  {
    id: 1,
    title: "My Dashboard",
    link: "/instructor/dashboard",
    icon: HomeIcon,
  },
  {
    id: 2,
    title: "My Courses",
    link: "/instructor/instructor-my-courses/",
    icon: BookOpenIcon,
  },
  // {
  //   id: 3,
  //   title: "Reviews",
  //   link: "/instructor/instructor-reviews/",
  //   icon: StarIcon,
  // },
  // {
  //   id: 4,
  //   title: "Earnings",
  //   link: "/instructor/instructor-earnings/",
  //   icon: ChartPieIcon,
  // },
  // {
  //   id: 5,
  //   title: "Orders",
  //   link: "/instructor/instructor-orders/",
  //   icon: ShoppingBagIcon,
  // },
  // {
  //   id: 6,
  //   title: "Students",
  //   link: "/instructor/instructor-students/",
  //   icon: UsersIcon,
  // },
  // {
  //   id: 7,
  //   title: "Payouts",
  //   link: "/instructor/instructor-payouts/",
  //   icon: CurrencyDollarIcon,
  // },
  {
    id: 8,
    title: "Quiz",
    link: "/instructor/quiz/",
    icon: QuestionMarkCircleIcon,
  },
  // {
  //   id: 9,
  //   title: "Quiz Result",
  //   link: "/instructor/quiz/result/",
  //   icon: QuestionMarkCircleIcon,
  // },
];

export const AccountSettingsMenu = [
  // {
  //   id: 1,
  //   title: "Edit Profile",
  //   link: "/instructor/instructor-edit-profile/",
  //   icon: Cog6ToothIcon,
  //   value: "settings",
  // },
  // {
  //   id: 2,
  //   title: "Security",
  //   link: "/instructor/instructor-security/",
  //   icon: UserIcon,
  //   value: "user",
  // },
  // {
  //   id: 3,
  //   title: "Social Profiles",
  //   link: "/instructor/instructor-social-profiles/",
  //   icon: ArrowPathIcon,
  //   value: "socialProfiles",
  // },
  // {
  //   id: 4,
  //   title: "Notifications",
  //   link: "/instructor/instructor-notifications/",
  //   icon: BellIcon,
  //   value: "notifications",
  // },
  // {
  //   id: 5,
  //   title: "Profile Privacy",
  //   link: "/instructor/instructor-profile-privacy/",
  //   icon: LockClosedIcon,
  //   value: "profilePrivacy",
  // },
  // {
  //   id: 6,
  //   title: "Delete Profile",
  //   link: "/instructor/instructor-delete-profile/",
  //   icon: TrashIcon,
  //   value: "deleteProfile",
  // },
  {
    id: 7,
    title: "Sign Out",
    link: "/",
    icon: PowerIcon,
    value: "signout",
  },
];

// user dashboard items import
export const UserDashboardTabsData = [
  {
    id: 1,
    text: "Dashboard",
    icon: RectangleGroupIcon,
  },
  // {
  //   id: 2,
  //   text: "All Courses",
  //   icon: BookOpenIcon,
  // },
  // {
  //   id: 3,
  //   text: "All Quizes",
  //   icon: QuestionMarkCircleIcon,
  // },
  // {
  //   id: 4,
  //   text: "Quiz Attempted",
  //   icon: TrophyIcon,
  // },
  {
    id: 5,
    text: "Math",
    icon: CalculatorIcon,
  },
  {
    id: 6,
    text: "Science",
    icon: BeakerIcon,
  },
  {
    id: 7,
    text: "English",
    icon: AcademicCapIcon,
  },
  {
    id: 8,
    text: "Social",
    icon: HandRaisedIcon,
  },
  {
    id: 9,
    text: "French",
    icon: AcademicCapIcon,
  },
  {
    id: 10,
    text: "Spanish",
    icon: AcademicCapIcon,
  },
  {
    id: 11,
    text: "Counselor",
    icon: BuildingLibraryIcon,
  },
];

// instructor dashboard tabs
export const InstructorDashboardTabsData = [
  {
    id: 1,
    text: "Dashboard",
    icon: RectangleGroupIcon,
  },
  {
    id: 2,
    text: "All Courses",
    icon: BookOpenIcon,
  },
  {
    id: 3,
    text: "All Quizes",
    icon: QuestionMarkCircleIcon,
  },
  {
    id: 4,
    text: "Students",
    icon: StudentIconBlack,
    darkModeIcon: StudentIconWhite,
    isImg: true,
  },
  {
    id: 5,
    text: "Orders",
    icon: OrdersIconBlack,
    darkModeIcon: OrdersIconWhite,
    isImg: true,
  },
  {
    id: 6,
    text: "Earnings",
    icon: EarningsIconBlack,
    darkModeIcon: EarningsIconWhite,
    isImg: true,
  },
  {
    id: 7,
    text: "Payouts",
    icon: CurrencyDollarIcon,
  },
  {
    id: 8,
    text: "My Blogs",
    icon: QueueListIcon,
  },
];

// profile section items
export const ProfileSectionTabs = [
  {
    id: 0,
    text: "Profile",
    selectedValue: "profile",
  },
  {
    id: 1,
    text: "Photo",
    selectedValue: "photo",
  },
  {
    id: 2,
    text: "Subscriptions",
    selectedValue: "subscriptions",
  },
  {
    id: 4,
    text: "Logout",
    selectedValue: "logout",
  },
];

export const AdminProfileUpdateTabs = [
  {
    id: 0,
    text: "Profile",
    selectedValue: "profile",
  },
  {
    id: 1,
    text: "Photo",
    selectedValue: "photo",
  },
];

export const ChooseCategoriesData = [
  {
    id: 1,
    name: "Development",
  },
  {
    id: 2,
    name: "Business",
  },

  {
    id: 3,
    name: "Finance & Accounting",
  },
  {
    id: 4,
    name: "IT & Software",
  },
  {
    id: 5,
    name: "Office Productivity",
  },
  {
    id: 6,
    name: "Personal Development",
  },
  {
    id: 7,
    name: "Design",
  },
];

export const AvailableSkillsLevel = [
  {
    id: 1,
    name: "Beginner",
  },
  {
    id: 2,
    name: "Intermediate",
  },
  {
    id: 3,
    name: "Advance",
  },
];

export const CourseFields = {
  category: "",
  field: "",
  imageUrl: "",
  videoUrl: "",
  duration: "",
  title: "",
  course_description: "",
  curriculum: [],
  tags: [],
  grades: [
    {
      name: "",
      chapters: [
        {
          name: "",
          headings: [
            {
              name: "",
              topics: [
                {
                  name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  level: "",
  price: 0,
};

export const UploadBlogFields = {
  title: "",
  description: "",
  shortDescription: "",
  recommendedFor: "",
  image: "",
  content: "",
  categories: "",
  readTime: "",
};

export const AdminDashboardTabs = [
  {
    id: 1,
    text: "Dashboard",
    icon: RectangleGroupIcon,
  },
  {
    id: 2,
    text: "All Courses",
    icon: BookOpenIcon,
  },
  {
    id: 3,
    text: "Instructor Accounts",
    icon: AcademicCapIcon,
  },
  {
    id: 4,
    text: "Blog Requests",
    icon: QueueListIcon,
  },
  {
    id: 5,
    text: "All Accounts",
    icon: UserCircleIcon,
  },
  {
    id: 6,
    text: "Add Course through CSV",
    icon: FolderPlusIcon,
  },
  {
    id: 7,
    text: "Check Course Status",
    icon: QuestionMarkCircleIcon,
  },
  {
    id: 8,
    text: "Add Details (for CSV Course)",
    icon: DocumentArrowUpIcon,
  },
  {
    id: 9,
    text: "Paid Student Accounts",
    icon: CurrencyDollarIcon,
  },
];
