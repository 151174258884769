import React, { useCallback, useContext, useState } from "react";
import AddButtonWithBorder from "../../../AddButtonWithBorder/AddButtonWithBorder";
import ReactQuill from "react-quill";
import { ToolbarOptions } from "../../../../../utils/utils";
import { DarkModeContext } from "../../../../../context/darkModeContext";
import useDebounce from "../../../../../hooks/useDebounce";

const AddBlogConclusion = ({ formData, handleChange, isEditing }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  const [showAddConclusionButton, setShowAddConclusionButton] = useState(
    isEditing ? false : true
  );
  const { debounce } = useDebounce();
  const [conclusion, setConclusion] = useState(formData?.conclusion || "");

  const handleDebouncedChange = useCallback(
    debounce((conclusionValue) => {
      handleChange({ target: { name: "conclusion", value: conclusionValue } });
    }, 400),
    [formData]
  );

  return (
    <div className="w-full">
      {showAddConclusionButton ? (
        <AddButtonWithBorder
          text={"Add Conclusion"}
          handleClick={() => setShowAddConclusionButton(false)}
        />
      ) : (
        <div className="px-4 py-2 space-y-2 border rounded-xl border-gray48 bg-gray49 dark:bg-purple24 dark:border-purple25">
          <p className="addBlogText">Add Conclusion</p>
          {/* short conclsuion */}
          <div className={`${darkMode ? "dark-mode" : ""}`}>
            <ReactQuill
              modules={{
                toolbar: ToolbarOptions,
              }}
              style={{
                height: "150px",
                marginBottom: "60px",
                // maxWidth: "512px",
                width: "100%",
              }}
              value={conclusion}
              onChange={(value) => {
                setConclusion(value);

                handleDebouncedChange(value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBlogConclusion;
