import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PaymentStatusModal from "../../modal/PaymentStatusModal";
import { UserContext } from "../../context/user";
import axios from "axios";
import { getURLs } from "../../urlConfig";

const PaymentStatusWrapper = ({ children }) => {
  const effectRan = useRef(false);
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get("payment");
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(
    paymentStatus ? true : false
  );
  const [currentStatus, setCurrentStatus] = useState(paymentStatus);

  useEffect(() => {
    if (searchParams.get("payment")) {
      setShowPaymentStatusModal(true);
      setCurrentStatus(searchParams.get("payment"));
    } else {
      setShowPaymentStatusModal(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      userInfo &&
      Object.keys(userInfo || {}).length > 0 &&
      currentStatus === "success" &&
      !effectRan?.current
    ) {
      axios
        .post(
          getURLs("getUserDetails"),
          {},
          {
            headers: { "auth-token": userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response?.data?.user) {
            updateUser({
              ...response?.data?.user,
              authToken: response?.data?.authToken,
            });
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {
      effectRan.current = true;
    };
  }, [userInfo, currentStatus]);

  const handleClosePopup = () => {
    const params = new URLSearchParams(searchParams);
    params.delete("payment");
    let newUrl = window.location.pathname;
    if (params.size > 0) {
      newUrl = `${newUrl}?${params.toString()}`;
    }
    window.history.replaceState(null, "", newUrl);
    setShowPaymentStatusModal(false);
    setCurrentStatus(null);
  };

  return (
    <>
      <div>{children}</div>
      {showPaymentStatusModal && (
        <PaymentStatusModal
          status={currentStatus}
          modalMessage={
            currentStatus === "success"
              ? "Your payment is successfully received"
              : "Your payment has failed, please try later"
          }
          handleClosePopup={handleClosePopup}
        />
      )}
    </>
  );
};

export default PaymentStatusWrapper;
