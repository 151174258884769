import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const ShowUserImage = ({ userImage, imgStyle = "", avatarStyle = "" }) => {
  const [imageLoadError, setImageLoadError] = useState(false);
  return (
    <div>
      {imageLoadError ? (
        <UserCircleIcon
          className={`!text-gray8 h-6 w-6 ${avatarStyle ? avatarStyle : ""}`}
        />
      ) : userImage ? (
        <img
          src={userImage}
          onError={() => {
            setImageLoadError(true);
          }}
          alt="user_profile"
          className={`object-contain h-6 w-6 rounded-full ${
            imgStyle ? imgStyle : ""
          }`}
        />
      ) : (
        <UserCircleIcon
          className={`!text-gray8 h-6 w-6 ${avatarStyle ? avatarStyle : ""}`}
        />
      )}
    </div>
  );
};

export default ShowUserImage;
