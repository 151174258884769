import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SubscriptionStripeCheckout from "../components/StripeCheckout/SubscriptionStripeCheckout";
import SubscriptionPaypalCheckout from "../components/PaypalCheckout/SubscriptionPaypalCheckout";
import PaymentStatusWrapper from "../components/Layout/PaymentStatusWrapper";

const Checkout = () => {
  const location = useLocation();
  // plan name state
  const [planName, setPlanName] = useState("");
  // selected payment method state
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  // subscription amount state
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  // subscription timeline
  const [subscriptionTimeline, setSubscriptionTimeline] = useState("");
  // planId
  const [planId, setPlanId] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const planName = searchParams.get("planName");
    const selectedPaymentMethod = searchParams.get("selectedPaymentMethod");
    const subscriptionAmount = searchParams.get("subscriptionAmount");
    const subscriptionTimeline = searchParams.get("subscriptionTimeline");
    const planId = searchParams.get("planId");
    setSelectedPaymentMethod(selectedPaymentMethod);
    setSubscriptionAmount(subscriptionAmount);
    setSubscriptionTimeline(subscriptionTimeline);
    setPlanName(planName);
    setPlanId(planId);
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>Brainjee - Checkout</title>
      </Helmet>
      <PaymentStatusWrapper>
        <div className="flex flex-col items-center px-4 lg:px-16 xl:px-64 py-6 lg:py-24">
          {/* left */}
          <div className="w-full max-w-xl flex flex-col space-y-4  border border-gray-100/2 shadow-md px-4 py-5">
            <h2 className="text-lg md:text-xl xl:text-2xl font-inter font-bold">
              Order Details
            </h2>

            <div className="flex items-center justify-between">
              <span className="font-inter font-medium text-sm md:text-base">
                Amount
              </span>
              <span className="font-inter font-semibold text-base md:text-lg">
                {`$ ${subscriptionAmount}`}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="font-inter font-medium text-sm md:text-base">
                Validity
              </span>
              <span className="font-inter font-semibold text-base md:text-lg">
                {subscriptionTimeline.toUpperCase()}
              </span>
            </div>
          </div>

          {selectedPaymentMethod === "stripe" ? (
            <SubscriptionStripeCheckout
              planName={planName}
              subscriptionAmount={subscriptionAmount}
              subscriptionTimeline={subscriptionTimeline}
            />
          ) : (
            <SubscriptionPaypalCheckout
              planName={planName}
              planId={planId}
              subscriptionAmount={subscriptionAmount}
              subscriptionTimeline={subscriptionTimeline}
            />
          )}
        </div>
      </PaymentStatusWrapper>
    </>
  );
};

export default Checkout;
