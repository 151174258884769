import { CheckIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/user";
import { DarkModeContext } from "../../../context/darkModeContext";
import CustomModal from "../../CustomModal/CustomModal";
import PaymentSection from "../../UserDashboard/PaymentSection/PaymentSection";

const SubscriptionCard = ({
  id,
  name,
  planImage,
  popularPlan,
  selected,
  description,
  planPriceMonthly,
  planPriceYearly,
  features,
  setSelectedPlan,
  subscriptionValue,
  setSubscriptionValue,
  planId,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const navigate = useNavigate();

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePaymentClick = () => {
    if (id === 2) {
      navigate("/contact");
    } else {
      if (Object.keys(userInfo)?.length) {
        setShowPaymentModal(true);
      } else {
        navigate("/login");
      }
    }
  };

  return (
    <div
      className={`relative dark:bg-purple2 bg-white flex flex-col p-5 rounded-2xl shadow-lg cursor-pointer w-full min-w-[300px] md:min-w-min ${
        selected ? "bg-background9" : "border dark:border-purple3"
      }`}
      onClick={() => setSelectedPlan(id)}
    >
      <div className="flex items-center justify-between mb-4">
        {/* <img loading="lazy" src={planImage} alt="plan_image" className="" /> */}
        <h1
          className={`font-bold font-inter text-xl ${
            selected ? "text-white" : " dark:text-white text-gray36"
          } md:text-2xl`}
        >
          {name}
        </h1>
        {popularPlan && (
          <div className="rounded-[45px] bg-white py-2 px-5">
            <span
              className="font-urbanist font-bold text-sm"
              style={{
                backgroundImage:
                  "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Popular Plan
            </span>
          </div>
        )}
      </div>

      {id === 2 ? (
        <p
          className={`block font-inter font-bold text-2xl md:text-4xl ${
            selected ? "text-white" : "text-purple3"
          } mb-3`}
        >
          {subscriptionValue === "monthly"
            ? `${planPriceMonthly}`
            : `${planPriceYearly}`}
        </p>
      ) : (
        <p
          className={`block font-urbanist font-semibold text-sm ${
            selected ? "text-white" : "text-purple3"
          } mb-3`}
        >
          <span className="font-urbanist font-bold text-2xl md:text-4xl">
            {subscriptionValue === "monthly"
              ? parseInt(planPriceMonthly) === 0
                ? "FREE"
                : `$${planPriceMonthly}`
              : parseInt(planPriceMonthly) === 0
              ? "FREE"
              : `$${planPriceYearly}`}
          </span>
          {parseInt(planPriceMonthly) !== 0 &&
            `/${subscriptionValue === "monthly" ? "Month" : "Year"}`}
        </p>
      )}

      <p
        className={`font-urbanist font-normal text-sm ${
          selected ? "text-white" : "text-gray28"
        }`}
      >
        {description}
      </p>

      <div
        className={`w-full border-b ${
          selected ? "border-b-white" : "border-b-gray-400"
        } my-3`}
      />

      <div className="flex flex-col mt-5 space-y-4 mb-8">
        {features.map((feature, index) => (
          <div key={index} className={`flex items-center space-x-2`}>
            <div
              className={`flex items-center justify-center h-5 w-5 rounded-full ${
                selected ? "bg-white" : "bg-gray38"
              } `}
            >
              <CheckIcon className="text-blue-500 h-3" />
            </div>
            <p
              className={`font-inter font-medium text-xs ${
                selected ? "text-white" : "dark:text-white text-blue21"
              }`}
            >
              {feature.featureDescription}
            </p>
          </div>
        ))}
      </div>

      <div className="flex-1 flex items-end">
        <button
          className={`flex items-center justify-center px-4 py-3 w-full bg-white font-inter font-bold rounded-[40px] mb-5`}
          style={{
            background:
              !selected && !darkMode
                ? "linear-gradient(white, white) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box"
                : !selected &&
                  darkMode &&
                  "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
            border: !selected && "1px solid transparent",
          }}
          disabled={!selected}
          onClick={handlePaymentClick}
        >
          <span
            className={`font-urbanist font-bold text-base`}
            style={{
              backgroundImage:
                "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {id === 2 ? "Contact" : "Get Started"}
          </span>
        </button>
      </div>
      <CustomModal
        onHide={() => setShowPaymentModal(false)}
        show={showPaymentModal}
        centered
      >
        <div className="w-full px-4 py-4">
          <PaymentSection
            userInfo={userInfo}
            usedAsPopup
            planName={name}
            setSubscriptionValue={setSubscriptionValue}
            subscriptionValue={subscriptionValue}
            priceForSubscription={
              subscriptionValue === "monthly"
                ? planPriceMonthly
                : planPriceYearly
            }
            handlePaymentClick={() => {}}
            closePopupHandler={() => setShowPaymentModal(false)}
            planId={planId}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default SubscriptionCard;
