import React, { useContext, useEffect, useState } from "react";
import InstructorCommonCardLayout from "../../InstructorCommonCardLayout/InstructorCommonCardLayout";
import StudentCard from "../../StudentCard/StudentCard";
import { UserContext } from "../../../../context/user";
import { InstructorDataContext } from "../../../../context/instructorData";
import axios from "axios";
import { getURLs } from "../../../../urlConfig";

const ShowEnrolledStudents = ({
  showViewAllButton,
  handleViewAllClick,
  showPagination = false,
  showSearch = false,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { enrolledStudents },
    updateEnrolledStudents,
  } = useContext(InstructorDataContext);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // page no state
  const [page, setPage] = useState(1);
  // search value
  const [searchTerm, setSearchTerm] = useState("");

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    updateEnrolledStudents([]);
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  useEffect(() => {
    // getting instructor info
    if (
      userInfo?.role?.includes("instructor") ||
      userInfo?.role?.includes("admin")
    ) {
      setLoading(true);
      // getting the students
      axios
        .get(getURLs("get-instructor-students"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          params: {
            page,
          },
        })
        .then((res) => {
          updateEnrolledStudents({ page, students: res?.data?.students });
          setFirstResponseCame(true);
          setLoading(false);
          setHasMore(res.data?.next ? true : false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userInfo, page, searchTerm]);

  return (
    <InstructorCommonCardLayout>
      {/* header */}
      <div className="flex items-center space-x-4 justify-between px-4 py-2 border-b border-b-gray-200 dark:border-b-purple25">
        <h5 className="font-urbanist text-blue34 font-medium dark:text-white">
          Students
        </h5>

        {showSearch && <div></div>}

        {showViewAllButton && (
          <button
            className="border-none font-urbanist font-medium text-blue-500 text-sm"
            onClick={handleViewAllClick}
          >
            View all
          </button>
        )}
      </div>

      {enrolledStudents && Object.keys(enrolledStudents)?.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 py-4 px-4 gap-4">
          {Object.keys(enrolledStudents).map((pageKey) =>
            enrolledStudents[pageKey]?.map((enrollment) => (
              <StudentCard
                key={enrollment?._id}
                studentData={{
                  image: enrollment?.student?.profileImage,
                  name: enrollment?.student?.name,
                  enrolledOn: enrollment?.enrollmentDate,
                  progress: enrollment?.courseProgress,
                  country: enrollment?.student?.country,
                }}
                courseName={enrollment?.course_details?.name}
              />
            ))
          )}
        </div>
      )}

      {showPagination && firstResponseCame && hasMore && (
        <div className="flex items-center justify-center z-10 pb-10">
          <button
            className="rounded-lg border-none text-white text-sm md:text-base font-raleway font-bold w-max px-10 py-2"
            style={{
              background: loading
                ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)"
                : "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            disabled={loading}
            onClick={handleLoadMore}
          >
            {loading ? "Loading..." : "Read More"}
          </button>
        </div>
      )}

      {/* if there are no respones and not loading */}
      {!loading && enrolledStudents?.length === 0 && (
        <h4 className="block py-4 font-medium font-urbanist text-center w-full text-black1 dark:text-white">
          No Students Available
        </h4>
      )}
    </InstructorCommonCardLayout>
  );
};

export default ShowEnrolledStudents;
