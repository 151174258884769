import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import { AppDataContext } from "../../context/appData";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardSidebar from "../../components/DashboardSidebar";
import ReactGA from "react-ga4";
import { AdminDashboardTabs } from "../../utils/staticData";
import AdminDashboardControls from "../../components/AdminPageComponents/AdminDashboardControls/AdminDashboardControls";
import AdminAllCourse from "../../components/AdminPageComponents/AdminAllCourses/AdminAllCourse";
import AdminShowInstructorAccounts from "../../components/AdminPageComponents/AdminShowInstructorAccounts/AdminShowInstructorAccounts";
import AdminShowBlogRequests from "../../components/AdminPageComponents/AdminShowBlogRequests/AdminShowBlogRequests";
import AdminShowAllUsers from "../../components/AdminPageComponents/AdminShowAllUsers/AdminShowAllUsers";
import AdminAddCourseThroughCSV from "../../components/AdminPageComponents/AdminAddCourseThroughCSV";
import AdminCheckCourseStatus from "../../components/AdminPageComponents/AdminAddCourseThroughCSV/AdminCheckCourseStatus";
import AdminAddDetailsCSVCourse from "../../components/AdminPageComponents/AdminAddDetailsCSVCourse";
import AdminShowPaidAccounts from "../../components/AdminPageComponents/AdminShowPaidAccounts";

const AdminDashboard = () => {
  const {
    state: { userInfo },
    logoutUser,
  } = useContext(UserContext);

  const {
    state: { appData, allCoursesData },
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(1);

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          sessionStorage.clear();
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`, // optional
    });
    setSelectedTab(tabID);
  };

  useEffect(() => {
    const isAdmin = userInfo?.role?.find((data) => data === "admin");
    if (Object.keys(userInfo)?.length && !isAdmin) {
      navigate("/login");
    }
  }, [userInfo, allCoursesData]);

  return (
    <>
      <Helmet>
        <title>Brainjee - Admin Dashboard</title>
        <link rel="canonical" href="https://www.brainjee.com/admin" />
      </Helmet>

      <div className="flex flex-col min-h-screen h-full bg-gray-100 dark:bg-darkBgColor1">
        <DashboardHeader
          pageData={appData?.homePageData}
          userInfo={userInfo}
          selectedTab={selectedTab}
          handleLogout={handleLogout}
          handleTabClick={handleTabClick}
          TabsData={AdminDashboardTabs}
        />
        <div className="flex h-full">
          <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
            <DashboardSidebar
              userInfo={userInfo}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              TabsData={AdminDashboardTabs}
            />
          </div>
          <div className="lg:basis-4/5 rounded-md w-full px-2 lg:pr-2">
            {selectedTab === 1 && (
              <AdminDashboardControls
                allCoursesData={allCoursesData}
                handleTabClick={handleTabClick}
                userInfo={userInfo}
              />
            )}

            {selectedTab === 2 && (
              <AdminAllCourse
                allCoursesData={allCoursesData}
                userInfo={userInfo}
              />
            )}

            {selectedTab === 3 && (
              <AdminShowInstructorAccounts userInfo={userInfo} />
            )}

            {selectedTab === 4 && <AdminShowBlogRequests userInfo={userInfo} />}

            {selectedTab === 5 && <AdminShowAllUsers userInfo={userInfo} />}
            {selectedTab === 6 && (
              <AdminAddCourseThroughCSV userInfo={userInfo} />
            )}
            {selectedTab === 7 && (
              <AdminCheckCourseStatus userInfo={userInfo} />
            )}
            {selectedTab === 8 && (
              <AdminAddDetailsCSVCourse userInfo={userInfo} />
            )}
            {selectedTab === 9 && <AdminShowPaidAccounts userInfo={userInfo} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
