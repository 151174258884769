import React from "react";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";

const SubscriptionCards = ({
  pageData,
  subscriptionValue,
  selectedPlan,
  setSelectedPlan,
  setSubscriptionValue,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 px-4 lg:px-16 xl:px-44 relative z-10">
      {pageData?.map((plan, index) => (
        <SubscriptionCard
          key={index}
          id={index}
          selected={selectedPlan === index}
          setSelectedPlan={setSelectedPlan}
          planImage={plan.planImage}
          popularPlan={plan.popularPlan}
          name={plan.planName}
          description={plan.planDescription}
          planPriceMonthly={plan.planPriceMontly}
          planPriceYearly={plan.planPriceYearly}
          features={plan.featuresAvailable}
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          planId={plan?._id}
        />
      ))}
    </div>
  );
};

export default SubscriptionCards;
