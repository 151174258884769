import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { UserContext } from "../../context/user";
import PaypalSubscriptionButtonsRender from "./PaypalSubscriptionButtonsRender";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import PaymentStatusModal from "../../modal/PaymentStatusModal";
import { getSuccessAndErrorUrlForSubscriptionPayments } from "../../utils/utils";

const SubscriptionPaypalCheckout = ({
  planId,
  planName,
  subscriptionAmount,
  subscriptionTimeline,
}) => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  // navigate state
  const navigate = useNavigate();
  // initial options for paypal
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "subscription",
    vault: true,
  };
  // error state
  const [error, setError] = useState("");

  // payment sucessful modal state
  const [showModal, setShowModal] = useState(false);

  const handleClosePopup = () => {
    navigate("/my-profile", { replace: true });
    setShowModal(false);
  };

  // custom handler func. to check whether the payment is successful or not
  const onApprove = async (data) => {
    setShowModal(true);
    if (userInfo && Object.keys(userInfo || {}).length > 0) {
      axios
        .post(
          getURLs("getUserDetails"),
          {},
          {
            headers: { "auth-token": userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response?.data?.user) {
            updateUser({
              ...response?.data?.user,
              authToken: response?.data?.authToken,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        {/* custom modal as paypal button state hook can only be used inside PaypalScriptProvider */}
        {subscriptionAmount &&
          planName &&
          subscriptionTimeline &&
          Object.keys(userInfo)?.length && (
            <PaypalSubscriptionButtonsRender
              error={error}
              setError={setError}
              onApprove={onApprove}
              planId={planId}
              subscriptionTimeline={subscriptionTimeline}
              successUrl={
                getSuccessAndErrorUrlForSubscriptionPayments().successUrl
              }
              errorUrl={getSuccessAndErrorUrlForSubscriptionPayments().errorUrl}
            />
          )}
      </PayPalScriptProvider>
      {showModal && (
        <PaymentStatusModal
          modalMessage={"Your payment has been successfully received"}
          status={"success"}
          handleClosePopup={handleClosePopup}
        />
      )}
    </>
  );
};

export default SubscriptionPaypalCheckout;
