import React, { useContext } from "react";
import { UserContext } from "../../../context/user";
import ShowUserImage from "../../ShowUserImage/ShowUserImage";
import ShowAudio from "../ShowAudio/ShowAudio";

const ShowQuestion = ({ question, type }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  return (
    <div className="flex items-center space-x-2 font-inter font-semibold text-base xl:text-lg text-blue4 mb-2">
      <span className="flex items-center">
        <ShowUserImage userImage={userInfo?.profileImage} />
        {": "}
      </span>
      {type === "audio" ? (
        <ShowAudio audioBlob={question} />
      ) : (
        <span className="text-start mt-1 dark:text-white text-gray30 font-urbanist font-medium text-sm sm:text-base">
          {question}
        </span>
      )}
    </div>
  );
};

export default ShowQuestion;
