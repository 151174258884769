import React from "react";
import CustomRadioButton from "../../../CustomRadioButton";

const ChangeUserRole = ({ account, role, handleRoleChange, index, page }) => {
  return (
    <div className="flex items-center space-x-2">
      <CustomRadioButton
        selected={account?.role?.includes(role?.toLowerCase()) || false}
        value={account?.role?.includes(role?.toLowerCase()) ? false : true}
        handleChange={(value) =>
          handleRoleChange(value, role?.toLowerCase(), account, index, page)
        }
      />
      <p className="font-urbanist text-sm font-medium dark:text-white">
        {role}
      </p>
    </div>
  );
};

export default ChangeUserRole;
