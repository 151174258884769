import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { Helmet } from "react-helmet-async";
import { AppDataContext } from "../context/appData";
import DashboardHeader from "../components/DashboardHeader";
import DashboardSidebar from "../components/DashboardSidebar";
import ReactGA from "react-ga4";
import { InstructorDashboardTabsData } from "../utils/staticData";
import AccountOverview from "../components/InstructorDashboardComponents/AccountOverview/AccountOverview";
import { InstructorDataContext } from "../context/instructorData";
import { fetchPageData } from "../utils/network-requests";
import InstructorBlogsOverview from "../components/InstructorDashboardComponents/InstructorBlogsOverview/InstructorBlogsOverview";
import InstructorAllCourses from "../components/InstructorDashboardComponents/InstructorAllCourses/InstructorAllCourses";
import ShowEnrolledStudents from "../components/InstructorDashboardComponents/AccountOverview/ShowEnrolledStudents/ShowEnrolledStudents";
import InstructorAllOrders from "../components/InstructorDashboardComponents/InstructorAllOrders.js/InstructorAllOrders";

const NewInstructorDashboard = () => {
  // navigate state
  const navigate = useNavigate();
  // user info context
  const {
    state: { userInfo },
    logoutUser,
  } = useContext(UserContext);

  // getting the instructor context
  const {
    state: { instructorCourses },
    getAllCourses,
  } = useContext(InstructorDataContext);

  // app data context
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const [loading, setLoading] = useState(true);

  // selected tab
  const [selectedTab, setSelectedTab] = useState(1);

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          sessionStorage.clear();
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`, // optional
    });
    setSelectedTab(tabID);
  };

  useEffect(() => {
    const isPersist = JSON.parse(localStorage.getItem("persist"));
    if (!Object.keys(userInfo).length && !isPersist) {
      navigate("/login", {
        replace: true,
      });
    }
    setLoading(false);
  }, [userInfo]);

  // fetching all the courses for the instructor
  useEffect(() => {
    // getting instructor info
    if (
      userInfo?.role?.includes("instructor") ||
      userInfo?.role?.includes("admin")
    ) {
      // getting the courses
      axios
        .get(getURLs("get-instructor-courses"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          getAllCourses(res?.data?.allCourses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData.instructorCreateCoursePage]);

  return (
    !loading && (
      <>
        <Helmet>
          <title>Brainjee - Instructor Dashboard</title>
          <link
            rel="canonical"
            href="https://www.brainjee.com/instructor/new-dashboard"
          />
        </Helmet>

        <div className="flex flex-col min-h-screen h-full bg-gray-100 dark:bg-darkBgColor1">
          <DashboardHeader
            pageData={appData?.homePageData}
            userInfo={userInfo}
            selectedTab={selectedTab}
            handleLogout={handleLogout}
            handleTabClick={handleTabClick}
            TabsData={InstructorDashboardTabsData}
          />
          <div className="flex h-full">
            <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
              <DashboardSidebar
                userInfo={userInfo}
                selectedTab={selectedTab}
                handleTabClick={handleTabClick}
                TabsData={InstructorDashboardTabsData}
              />
            </div>
            <div className="lg:basis-4/5 rounded-md w-full px-2 lg:pr-2">
              {selectedTab === 1 && (
                <AccountOverview
                  instructorCourses={instructorCourses}
                  userInfo={userInfo}
                  setSelectedTab={setSelectedTab}
                />
              )}

              {selectedTab === 2 && (
                <div className="py-4">
                  <InstructorAllCourses instructorCourses={instructorCourses} />
                </div>
              )}

              {selectedTab === 4 && (
                <div className="py-4">
                  <ShowEnrolledStudents showSearch showPagination />
                </div>
              )}

              {selectedTab === 5 && (
                <div className="py-4">
                  <InstructorAllOrders showPagination />
                </div>
              )}

              {selectedTab === 8 && (
                <InstructorBlogsOverview
                  instructorCourses={instructorCourses}
                  userInfo={userInfo}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default NewInstructorDashboard;
