import {
  ADD_USER,
  UPDATE_SEARCHES_LEFT_FOR_USER,
  UPDATE_USER_BOT_INFO,
  UPDATE_USER_BOT_ID_INFO,
  UPDATE_USER_CHAT_ID_INFO,
  LOGOUT_USER,
  UPDATE_BOOKMARKED_COURSES,
} from "./keys";

export const updateUser = (dispatch) => (user) => {
  dispatch({
    type: ADD_USER,
    payload: { ...user },
  });
};

export const updateSearchesLeftForUser = (dispatch) => (searchesLeft) => {
  dispatch({
    type: UPDATE_SEARCHES_LEFT_FOR_USER,
    payload: searchesLeft,
  });
};

export const updateUserBotInfo = (dispatch) => (userBotInfo) => {
  dispatch({
    type: UPDATE_USER_BOT_INFO,
    payload: userBotInfo,
  });
};

export const updateUserBotIdInfo = (dispatch) => (userBotIdsInfo) => {
  dispatch({
    type: UPDATE_USER_BOT_ID_INFO,
    payload: userBotIdsInfo,
  });
};

export const updateUserChatIdInfo = (dispatch) => (userchatIdInfo) => {
  dispatch({
    type: UPDATE_USER_CHAT_ID_INFO,
    payload: userchatIdInfo,
  });
};

export const logoutUser = (dispatch) => () => {
  dispatch({
    type: LOGOUT_USER,
    payload: {},
  });
};

export const updateUserBookmarkedCourses =
  (dispatch) => (bookmarkedCourses) => {
    dispatch({
      type: UPDATE_BOOKMARKED_COURSES,
      payload: bookmarkedCourses,
    });
  };
