import React, { useContext, useState } from "react";
import ChooseSubscription from "../ChooseSubscription";
import SubscriptionCards from "../SubscriptionCards";
import { AppDataContext } from "../../context/appData";
import PaymentStatusWrapper from "../Layout/PaymentStatusWrapper";

const ShowPricingSubscription = ({ pricingPageData }) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const [subscriptionValue, setSubscriptionValue] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(1);

  return (
    <PaymentStatusWrapper>
      <section className="mb-8 pt-8">
        <ChooseSubscription
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          pageData={pricingPageData}
        />
      </section>

      <section className="mb-8 xl:mb-32">
        <SubscriptionCards
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          pageData={appData?.availableSubscriptionsData}
        />
      </section>
    </PaymentStatusWrapper>
  );
};

export default ShowPricingSubscription;
