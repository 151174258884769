import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";
import AdminFilterTabsWithSearch from "../AdminFilterTabsWithSearch/AdminFilterTabsWithSearch";
import CustomPagination from "../../CustomPagination";
import { Link, useNavigate } from "react-router-dom";
import {
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import LevelIcon from "../../LevelIcon";

const tabs = ["All", "Approved", "Pending"];

const AdminAllCourse = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all");

  const [pagedResponse, setPagedResponse] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // disable action button state
  const [disbaleActionButtons, setDisableActionButtons] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // fetch fresh records
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  const handleChangeStatus = (id, status) => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      if (disbaleActionButtons) return;

      setDisableActionButtons(true);
      axios
        .post(
          getURLs("change-status"),
          {
            status,
            changeStatusFor: "book",
            id,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedPagedResponse = { ...pagedResponse };
            updatedPagedResponse[page] = updatedPagedResponse[page]?.map(
              (book) => {
                if (book._id === id) {
                  return {
                    ...book,
                    status: status,
                  };
                } else {
                  return book;
                }
              }
            );
            setPagedResponse(updatedPagedResponse);
            setDisableActionButtons(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableActionButtons(false);
        });
    }
  };

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setFetchFreshRecords(true);
  };

  const handleEditButtonClick = (courseSlug) => {
    navigate(`/update-course/${courseSlug}`);
  };

  const handleCourseDelete = (bookId, page, bookIndex, authorId = "") => {
    if (
      userInfo &&
      Object.keys(userInfo || {})?.length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      setPagedResponse((prevState) => {
        const updatedPage = prevState[page].filter(
          (_, index) => index !== bookIndex
        );
        return {
          ...prevState,
          [page]: updatedPage,
        };
      });
      axios
        .delete(
          `${getURLs("delete-book")}?bookId=${bookId}&authorId=${authorId}`,
          {
            headers: {
              "auth-token": userInfo?.authToken,
            },
            withCredentials: true,
          }
        )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("all-books"), {
          params: {
            page,
            search: searchTerm,
            status: selectedTab,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.AllCoursesData,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [
    userInfo,
    page,
    searchTerm,
    selectedTab,
    pagedResponse,
    fetchFreshRecords,
  ]);

  return (
    <div className="flex flex-col bg-bgColor3 px-4 py-5">
      <h1 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black">
        All Courses
      </h1>
      {/* filter section */}
      <AdminFilterTabsWithSearch
        handleSearch={handleSearch}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        tabsData={tabs}
      />

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[850px]">
          <div className="tableHeaderText col-span-3 text-start">
            Course Information
          </div>
          <div className="tableHeaderText">Author</div>
          <div className="tableHeaderText">Status</div>
          <div className="tableHeaderText border-none text-center col-span-3">
            Action
          </div>
        </div>

        <div className="min-w-[850px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((book, index) => (
                <div className="" key={book._id}>
                  {/* body */}
                  <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-3 flex items-center space-x-4">
                      <Link to={`/course-details/${book.urlSlug}`}>
                        <div className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-y-0 md:space-x-4">
                          <div>
                            {book?.imageUrl ? (
                              <img
                                src={book?.imageUrl}
                                alt="course_thumbnail"
                                className="rounded-md h-20 w-20 object-contain"
                              />
                            ) : (
                              <PhotoIcon className="h-20 w-20 text-gray-400 dark:text-purple25" />
                            )}
                            {/* </Link> */}
                          </div>
                          <div className="lg:ms-3 mt-2 lg:mt-0">
                            <h4 className="mb-1 text-base font-inter font-medium text-black">
                              {/* <Link to="#" className="text-inherit"> */}
                              {book?.name}
                            </h4>
                            {book?.course_level && (
                              <div className="mb-0 flex items-center space-x-2">
                                <p className="flex items-center space-x-2">
                                  <LevelIcon
                                    level={book?.course_level?.toLowerCase()}
                                  />
                                  {book?.course_level}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>

                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {book?.author || "-"}
                    </p>

                    {/* status */}
                    <div className="flex items-center justify-center gap-1 h-max">
                      <div
                        className={`${
                          book?.status === "pending"
                            ? "bg-orange-500"
                            : book?.status === "approved"
                            ? "bg-green-500"
                            : book?.status === "rejected"
                            ? "bg-red-500"
                            : "bg-yellow-500"
                        } flex h-2 w-2 rounded-full`}
                      />
                      <p className="font-urbanist text-sm font-semibold dark:text-white ">
                        {book?.status?.toUpperCase() || "DRAFT"}
                      </p>
                    </div>

                    {/* action */}
                    <div className="flex flex-col space-y-1 justify-center items-center col-span-3">
                      <div className="flex items-center space-x-2">
                        <button
                          className="flex items-center justify-center px-5 py-1 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
                          style={{
                            background: !disbaleActionButtons
                              ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                              : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                          }}
                          disabled={disbaleActionButtons}
                          onClick={() => handleEditButtonClick(book?.urlSlug)}
                        >
                          <PencilSquareIcon className="text-white h-4 w-4 mr-2" />
                          Edit
                        </button>
                        {book?.status && book?.status !== "draft" && (
                          <div className="flex items-center justify-center space-x-2 font-urbanist font-medium text-sm">
                            {book.status !== "approved" && (
                              <button
                                className="bg-background8 disabled:bg-background6 font-urbanist font-medium text-white rounded-xl px-2 py-1 text-sm"
                                onClick={() =>
                                  handleChangeStatus(book._id, "approved")
                                }
                                disabled={disbaleActionButtons}
                              >
                                Approve
                              </button>
                            )}
                            {book.status !== "pending" && (
                              <button
                                className="px-6 py-1 text-black1 disabled:text-gray-300 dark:disabled:text-gray-500 dark:text-white font-urbanist font-medium rounded-xl border border-gray-400 disabled:bg-gray-50 dark:bg-purple24 dark:disabled:bg-purple26/30 dark:border-purple26 dark:border-purple-26 text-sm"
                                onClick={() =>
                                  handleChangeStatus(book._id, "pending")
                                }
                                disabled={disbaleActionButtons}
                              >
                                Pending
                              </button>
                            )}
                            {book.status !== "rejected" && (
                              <button
                                className="rounded-xl border dark:border-none text-white px-2 py-1 bg-red-500 disabled:bg-red-200"
                                onClick={() =>
                                  handleChangeStatus(book._id, "rejected")
                                }
                                disabled={disbaleActionButtons}
                              >
                                {"Reject"}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className="rounded-xl border dark:border-none text-white px-2 py-1 bg-red-700 disabled:bg-red-200 cursor-pointer w-max flex items-center space-x-2"
                        onClick={() =>
                          handleCourseDelete(
                            book._id,
                            page,
                            index,
                            book?.author_id
                          )
                        }
                      >
                        <p className="font-urbanist font-normal text-white text-sm">
                          Delete
                        </p>
                        <TrashIcon className="text-white h-3 w-3" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Courses available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </div>
  );
};

export default AdminAllCourse;
