import React, { useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import MathJax from "react-mathjax2";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { Line, Bar, Pie } from "react-chartjs-2";
import { DarkModeContext } from "../../context/darkModeContext";
import "katex/dist/katex.min.css";
import "./Markdown.css";
import {
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

const NewMathRender = ({ markdown }) => {
  const { darkMode } = useContext(DarkModeContext);
  // Replace \[ with $$ and \] with $$ to ensure compatibility
  const processedText = markdown
    ?.replace(/\\\[/g, "$$$") // Replace all occurrences of \[ with $$
    ?.replace(/\\\]/g, "$$$") // Replace all occurrences of \] with $$
    ?.replace(/\\\(/g, "$$$") // Replace all occurrences of \( with $$
    ?.replace(/\\\)/g, "$$$"); // Replace all occurrences of \) with $$

  const remarkMathOptions = {
    singleDollarTextMath: false,
  };
  const [showOptions, setShowOptions] = useState(false);
  const [showTextCopied, setShowTextCopied] = useState(false);

  const handleTextCopy = () => {
    navigator.clipboard.writeText(markdown);
    setShowTextCopied(true);
    setTimeout(() => {
      setShowTextCopied(false);
    }, 1000);
  };

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={darkMode ? a11yDark : materialLight}
          language={match[1]}
          PreTag="div"
          customStyle={{
            borderRadius: "10px",
          }}
          wrapLongLines
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    img: ({ src, alt }) => (
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: "100%",
          height: "auto",
          maxHeight: "400px",
          objectFit: "contain",
          display: "block",
          margin: "1rem auto",
        }}
      />
    ),
    a: ({ href, children }) => {
      // Check if the href is an image link
      if (/\.(png|jpe?g|gif|svg|webp)(\?.*)?$/i.test(href)) {
        return (
          <img
            src={href}
            alt={children[0]}
            style={{
              maxWidth: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "contain",
              display: "block",
              margin: "1rem auto",
            }}
          />
        );
      }
      // Render regular links as usual
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  };

  return (
    <div
      className="markdown-container text-sm break-words"
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <MathJax.Context
        input="ascii"
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
        options={{
          asciimath2jax: {
            useMathMLspacing: false,
            delimiters: [
              ["$$", "$$"],
              ["$", "$"],
              ["\\(", "\\)"],
            ],
          },
        }}
      >
        <MathJax.Text
          className="w-full"
          text={
            <ReactMarkdown
              children={processedText}
              remarkPlugins={[[remarkMath, remarkMathOptions], remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeKatex]}
              components={components}
            />
          }
        />
      </MathJax.Context>

      <div className="h-8">
        {showOptions && (
          <div className={`pt-1 pb-2`}>
            {showTextCopied ? (
              <ClipboardDocumentCheckIcon className="text-gray-600 dark:text-white h-5 w-5 cursor-pointer" />
            ) : (
              <button onClick={handleTextCopy}>
                <ClipboardIcon className="text-gray-600 dark:text-white h-5 w-5 cursor-pointer" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewMathRender;
