import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const PaymentStatusModal = ({ handleClosePopup, modalMessage, status }) => {
  return (
    <div className="fixed top-0 left-0 bottom-0 bg-black/70 z-[100] flex items-center justify-center h-screen w-screen">
      <div className="relative flex flex-col items-center bg-white border border-gray-400 rounded-xl shadow-md max-w-xl w-full px-10 py-6 pb-10 space-y-6">
        <button
          className="absolute top-1 right-1 text-gray-900 text-xs px-2 py-2 text-center flex self-end border border-gray-600/60 items-center justify-center rounded-full"
          onClick={handleClosePopup}
        >
          <XMarkIcon className="text-black h-3 w-3" />
        </button>

        <div
          className={`${
            status === "success" ? "bg-green-500/70" : "bg-red-500"
          } rounded-full px-2 py-2 w-max"`}
        >
          {status === "success" ? (
            <CheckIcon className="text-white h-4 w-4" />
          ) : (
            <XMarkIcon className="text-white h-4 w-4" />
          )}
        </div>
        <span className="font-semibold font-urbanist text-black text-xl sm:text-2xl text-center">
          {modalMessage}
        </span>
      </div>
    </div>
  );
};

export default PaymentStatusModal;
