import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CustomRadioButton from "../../CustomRadioButton";
import { parseQuizContent } from "../../../utils/utils";

const SectionQuiz = ({ markdownContent, sectionContent }) => {
  const questions = parseQuizContent(markdownContent);
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);

  const handleOptionSelect = (questionIndex, option) => {
    setUserAnswers({
      ...userAnswers,
      [questionIndex]: option,
    });
  };

  const handleSubmit = () => {
    setShowResults(true);
  };

  useEffect(() => {
    setUserAnswers({});
    setShowResults(false);
  }, [sectionContent]);

  return (
    <div className="flex flex-col space-y-6">
      {questions?.map((question, index) => (
        <div
          key={index}
          className="flex flex-col space-y-2 text-sm font-urbanist font-medium dark:text-white"
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {question.question}
          </ReactMarkdown>
          <div className="md:border dark:md:border-none">
            {question.options.map((option, optionIndex) => (
              <div
                key={optionIndex}
                className={`flex items-center space-x-4 px-4 py-4 ${
                  userAnswers[index] === option
                    ? "bg-gray21 dark:bg-purple16"
                    : "bg-white dark:bg-purple14"
                } hover:bg-gray21 dark:hover:bg-purple16 border-b dark:border-b-purple15 cursor-pointer ${
                  index === 0
                    ? "hover:rounded-tl hover:rounded-tr rounded-t-md"
                    : index + 1 === question.options.length
                    ? "hover:rounded-b-md"
                    : ""
                }`}
                onClick={() => handleOptionSelect(index, option)}
              >
                <CustomRadioButton
                  selected={userAnswers[index] === option}
                  value={index}
                  handleChange={(value) => handleOptionSelect(index, option)}
                />
                <p className="text-gray22 dark:text-gray-300 font-urbanist font-normal text-sm">
                  {option}
                </p>
              </div>
            ))}
          </div>
          {showResults && (
            <div
              className={`font-urbanist font-medium text-sm ${
                userAnswers[index] &&
                userAnswers[index] === question.correctAnswer
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {userAnswers[index] &&
              userAnswers[index] === question.correctAnswer
                ? "Correct!"
                : question?.correctAnswer
                ? `Incorrect, the correct answer is: ${question?.correctAnswer}`
                : "Correct Answer not provided for the question"}
            </div>
          )}
        </div>
      ))}
      {!showResults && (
        <div className="flex items-center justify-end mb-2">
          <button
            className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={handleSubmit}
          >
            Submit Quiz
          </button>
        </div>
      )}
    </div>
  );
};

export default SectionQuiz;
