import React, { useCallback, useContext, useEffect, useState } from "react";
import ImageUpload from "../../../ImageUpload";
import AddBlogSection from "./AddBlogSection/AddBlogSection";
import AddBlogConclusion from "./AddBlogConclusion/AddBlogConclusion";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import AddBlogInput from "./AddBlogInput/AddBlogInput";
import { AppDataContext } from "../../../../context/appData";
import ReactQuill from "react-quill";
import { DarkModeContext } from "../../../../context/darkModeContext";
import { ToolbarOptions } from "../../../../utils/utils";
import useDebounce from "../../../../hooks/useDebounce";

const UploadBlog = ({
  formData,
  handleChange,
  handleUpload,
  disableUploadButton,
  isEditing,
  sections,
  setSections,
  handlePreviewClick,
}) => {
  const [description, setDescription] = useState(formData?.description || "");
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  const { debounce } = useDebounce();
  const createBlogPageData = appData?.createBlogPageData;
  const handleImageChage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const base64String = readerEvent?.target?.result;
        const event = {
          target: { name: "image", value: base64String },
        };
        handleChange(event);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleDebouncedChange = useCallback(
    debounce((descriptionValue) => {
      handleChange({
        target: { name: "description", value: descriptionValue },
      });
    }, 400),
    [formData]
  );

  useEffect(() => {
    if (Array.isArray(formData?.content)) {
      setSections(formData?.content);
    } else {
      const updatedContent = formData?.content?.split("<br />");
      const allSubheadings = [];
      const allContents = [];
      updatedContent?.forEach((value, index) => {
        if (value?.length > 0) {
          if (index % 2 === 0) {
            allSubheadings.push(value.replace(/<\/?[^>]+(>|$)/g, ""));
          } else {
            allContents.push(value);
          }
        }
      });

      const allSections = [];
      if (allSubheadings?.length >= allContents) {
        allSubheadings?.forEach((subheading, index) => {
          allSections.push({
            subHeading: subheading,
            content: allContents?.[index] || "",
          });
        });
      } else {
        allContents?.forEach((content, index) => {
          allSections.push({
            subHeading: allSubheadings?.[index] || "",
            content: content || "",
          });
        });
      }
      setSections(allSections);
    }
  }, [formData?.content]);

  return (
    <div className="w-full">
      {/* header */}
      <div className="px-4 py-2 border-b border-b-gray-100 dark:border-b-purple25 flex items-center justify-between space-x-4">
        <p className="font-urbanist text-base text-black font-blod dark:text-white">
          Upload your blog
        </p>
      </div>

      {/* add blog info */}
      <div className="px-4 py-4 flex flex-col md:flex-row md:items-start space-y-4 space-x-0 md:space-y-0 md:space-x-8">
        {/* add image */}
        <div className="md:basis-1/3 w-full flex flex-col space-y-2">
          <input
            type="file"
            id="input-file-upload-course"
            hidden
            accept=".png, .jpeg, .jpg, .svg"
            onChange={handleImageChage}
          />

          <label
            htmlFor="input-file-upload-course"
            className="w-full space-y-2"
          >
            <div className="md:basis-1/2 w-full">
              <ImageUpload />
            </div>

            {formData?.image && (
              <div className="md:basis-1/2 w-full">
                <img
                  src={formData?.image}
                  alt="course_thumbnail"
                  className="w-full max-h-80 object-cover rounded-xl"
                />
              </div>
            )}
          </label>

          {/* add more image button */}
        </div>

        {/* blog text info */}
        <div className="md:basis-2/3 w-full flex flex-col space-y-4">
          {/* heading */}
          <AddBlogInput
            inputName={"title"}
            inputPlaceholder={"Add Heading"}
            inputTitle={"Add Heading"}
            inputValue={formData?.title}
            handleChange={handleChange}
            useInput
          />
          {/* short description */}
          <AddBlogInput
            inputName={"shortDescription"}
            inputPlaceholder={"Add short description"}
            inputTitle={"Add Subtitle"}
            inputValue={formData?.shortDescription}
            handleChange={handleChange}
            useTextArea
            rows={2}
          />
          <div className="space-y-1">
            <p className="addBlogText">Description</p>
            <div className={`${darkMode ? "dark-mode" : ""}`}>
              <ReactQuill
                modules={{
                  toolbar: ToolbarOptions,
                }}
                style={{
                  height: "150px",
                  marginBottom: "60px",
                  // maxWidth: "512px",
                  width: "100%",
                }}
                value={description}
                onChange={(value) => {
                  setDescription(value);
                  handleDebouncedChange(value);
                }}
              />
            </div>
          </div>

          {/* Read Time */}
          <AddBlogInput
            inputName={"readTime"}
            inputPlaceholder={"Add Read time"}
            inputTitle={"Read time"}
            inputValue={formData?.readTime || ""}
            handleChange={handleChange}
            useSelect
            options={createBlogPageData?.readTimeOptions?.data}
          />
          {/* 
          {/* Recommended for*/}
          <AddBlogInput
            inputName={"recommendedFor"}
            inputPlaceholder={"Recommended for"}
            inputTitle={"Recommended for"}
            inputValue={formData?.recommendedFor || ""}
            handleChange={handleChange}
            useSelect
            options={createBlogPageData?.recommendedOptions?.data}
          />
          {/* Catgories */}
          <AddBlogInput
            inputName={"categories"}
            inputPlaceholder={"Categories"}
            inputTitle={"Categories"}
            inputValue={formData?.categories || ""}
            handleChange={handleChange}
            useSelect
            options={createBlogPageData?.categoriesOptions?.data}
          />

          {/* Add Section button */}
          <AddBlogSection
            formData={formData}
            handleChange={handleChange}
            sections={sections}
            setSections={setSections}
            isEditing={isEditing}
          />
          {/* Add Question button */}
          {/* Add conclusion button */}
          <AddBlogConclusion
            formData={formData}
            handleChange={handleChange}
            isEditing={isEditing}
          />
          {/* upload button */}
          <div className="flex items-center space-x-2 justify-between">
            {/* upload and save button */}
            <div className="flex items-center space-x-2">
              <button
                className="px-6 py-1 text-black1 dark:text-white font-urbanist font-medium rounded-xl border border-gray-400 dark:bg-purple24 dark:border-purple-26 text-sm"
                onClick={handlePreviewClick}
              >
                Preview
              </button>
              <button
                className="px-6 py-1 rounded-xl"
                style={{
                  background:
                    "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
                }}
                onClick={() => handleUpload(sections, false)}
              >
                <p className="w-full font-urbanist text-sm text-white text-center font-bold">
                  Save
                </p>
              </button>
            </div>

            {/* upload button */}
            <button
              className="w-max flex items-center px-8 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl disabled:bg-blue-200"
              style={{
                background:
                  !disableUploadButton &&
                  "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
              }}
              onClick={() => handleUpload(sections, true)}
              disabled={disableUploadButton}
            >
              <ArrowUpTrayIcon className="text-white h-4 w-4 mr-2" />
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBlog;
