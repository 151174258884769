import React, { useEffect, useState } from "react";
import AdminFilterTabsWithSearch from "../AdminFilterTabsWithSearch/AdminFilterTabsWithSearch";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import CustomPagination from "../../CustomPagination";

const AdminShowPaidAccounts = ({ userInfo }) => {
  const [pagedResponse, setPagedResponse] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // fetch fresh records
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("paid-accounts"), {
          params: {
            page,
            search: searchTerm,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.subscriptions,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [userInfo, page, searchTerm, pagedResponse, fetchFreshRecords]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 px-4 py-5">
      <h1 className="font-inter font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        Student Paid Accounts
      </h1>
      {/* filter section */}
      <AdminFilterTabsWithSearch handleSearch={handleSearch} />

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-6 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[800px]">
          <div className="tableHeaderText col-span-2 text-start">Email</div>
          <div className="tableHeaderText">Name</div>
          <div className="tableHeaderText">Enrolled on</div>
          <div className="tableHeaderText">Last Payment Date</div>
          <div className="tableHeaderText">Payment Vendor</div>
        </div>

        <div className="min-w-[800px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((details) => (
                <div className="" key={details._id}>
                  {/* body */}
                  <div className="grid grid-cols-6 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-2 flex items-center space-x-4">
                      <h2 className="font-urbanist font-medium text-black dark:text-white w-full truncate">
                        {details?.studentDetails?.email}
                      </h2>
                    </div>

                    {/* name */}
                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {details?.studentDetails?.name}
                    </p>
                    {/* enrollment date */}
                    <p className="font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      {new Date(details?.startDate).toDateString()}
                    </p>

                    {/* last payment made */}
                    <p className="font-urbanist text-sm font-semibold dark:text-white text-center">
                      {new Date(details?.lastPaymentDate).toDateString()}
                    </p>

                    {/* vendor */}
                    <p className="font-urbanist text-sm font-semibold dark:text-white text-center">
                      {details?.vendor}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Paid Accounts available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </div>
  );
};

export default AdminShowPaidAccounts;
