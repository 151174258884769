// import node module libraries
import TestimonialsSlider from "./TestimonialSlider/TestimonialSlider";
import { useContext } from "react";
import { AppDataContext } from "../../context/appData";
import BulbIcon from "../../images/bulb2.png";

const NewReviewsSection = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  return (
    <section className="dark:bg-darkBgColor1 bg-white pb-16 px-4 md:px-14 lg:px-24 pt-10">
      <div className="mb-16 flex flex-col-reverse lg:flex-row items-center">
        <div className="lg:basis-1/2 w-full flex flex-col items-center lg:items-start">
          <h2 className="text-4xl dark:text-white font-urbanist font-bold tracking-wide block mb-4">
            {appData?.homePageData?.whatCustomerSaySectionData?.heading}
          </h2>
          <p className="mb-0 text-center lg:text-start max-w-lg text-sm tracking-wide font-inter font-normal dark:text-white text-gray18">
            {
              appData?.homePageData?.whatCustomerSaySectionData
                ?.beforeBoldWordText
            }
            <span className="text-black dark:text-white font-bold">
              {appData?.homePageData?.whatCustomerSaySectionData?.boldWord1}
            </span>{" "}
            <span className="text-black dark:text-white font-bold">
              {appData?.homePageData?.whatCustomerSaySectionData?.boldWord2}
            </span>
            {" and "}
            <span className="text-black dark:text-white font-bold">
              {appData?.homePageData?.whatCustomerSaySectionData?.boldWord3}
            </span>{" "}
            {
              appData?.homePageData?.whatCustomerSaySectionData
                ?.afterBoldWordText
            }
          </p>
        </div>
        <div className="lg:basis-1/2 w-full flex items-center justify-center">
          <img
            src={BulbIcon}
            alt="buld-icon"
            className="w-24 h-24 object-contain"
          />
        </div>
      </div>
      <div className="relative mt-4">
        {/*  Testimonial slider */}
        <TestimonialsSlider
          TestimonialsList={
            appData?.homePageData?.whatCustomerSaySectionData?.TestimonialsList
          }
        />
      </div>
    </section>
  );
};

export default NewReviewsSection;
