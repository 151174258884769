import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../../context/appData";
import SubscriptionToggle from "../../ChooseSubscription/SubscriptionToggle/SubscriptionToggle";
import ActivePlan from "./ActivePlan/ActivePlan";
import AvailablePlans from "./AvailablePlans/AvailablePlans";

const ProfileSubscription = ({ userInfo }) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [availablePlansData, setAvailablePlansData] = useState();

  const [subscriptionValue, setSubscriptionValue] = useState("monthly");
  const [selectedPlanDetails, setSelectedPlansDetails] = useState({});

  useEffect(() => {
    const data = appData?.availableSubscriptionsData?.filter(
      (card) => card.planName !== "Basic"
    );
    const selectedPlanDetails = appData?.availableSubscriptionsData?.find(
      (card) => card._id === userInfo?.subscriptionDetails?.subscriptionId._id
    );
    setSelectedPlansDetails(selectedPlanDetails);
    setAvailablePlansData(data);
  }, [appData?.availableSubscriptionsData, userInfo]);

  return (
    <>
      <div className="flex flex-col px-5 py-4 w-full">
        <h1 className="font-urbanist font-semibold text-xl dark:text-white text-black block mb-3">
          Subscriptions
        </h1>

        <h5 className="font-urbanist font-medium dark:text-white text-gray30 text-sm block mb-8">
          Manage your brainjee subscriptions
        </h5>

        <ActivePlan
          planData={selectedPlanDetails}
          activePlanDetails={{
            activePlanName:
              userInfo?.subscriptionDetails?.subscriptionId?.planName ||
              "Basic",
            subAmount: userInfo?.subscriptionDetails?.amount || 0,
            subscriptionEndDate:
              userInfo?.subscriptionDetails?.subscriptionEndDate,
            subscriptionStartDate: userInfo?.subscriptionDetails?.createdAt,
            paymentVendor: userInfo?.subscriptionDetails?.paymentVendor,
          }}
        />

        <section className="mb-8 pt-8">
          <SubscriptionToggle
            setSubscriptionValue={setSubscriptionValue}
            subscriptionValue={subscriptionValue}
          />
        </section>

        <section className="w-full mb-8 xl:mb-32">
          <AvailablePlans
            setSubscriptionValue={setSubscriptionValue}
            subscriptionValue={subscriptionValue}
            availablePlansData={availablePlansData}
            activePlan={selectedPlanDetails?.planName}
          />
        </section>
      </div>
    </>
  );
};

export default ProfileSubscription;
